export const LogoPartake = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 222.5 56" enableBackground="new 0 0 222.5 56" xmlSpace="preserve">
			<g>
				<defs>
					<path id="partake" d="M0.5,31.6c0-17.1,13.9-31.1,31.1-31.1c10.4,0,19.6,5.1,25.3,13c-2.9,0.9-5.6,2.4-7.7,4.5
						c-3.5,3.4-5.4,8.2-5.4,13.3L0.5,31.6z"/>
				</defs>
				<clipPath id="SVGID_00000000219769171377334040000012201754699550564779_">
					<use xlinkHref="#partake" overflow="visible" />
				</clipPath>

				<linearGradient id="SVGID_00000019644682251582652870000005973404664131028653_" gradientUnits="userSpaceOnUse" x1="2.3251" y1="-897.9" x2="60.8749" y2="-897.9" gradientTransform="matrix(1 0 0 -1 0 -884)">
					<stop offset="0" style={{ stopColor: "#96D5FF" }} />
					<stop offset="1" style={{ stopColor: "#CB96FF" }} />
				</linearGradient>

				<path clipPath="url(#SVGID_00000000219769171377334040000012201754699550564779_)" fill="url(#SVGID_00000019644682251582652870000005973404664131028653_)" d="
					M10.2,27.3c-0.5,0-1-0.1-1.4-0.3l-3.9-1.5c-2.1-0.8-3.1-3.1-2.3-5.2s3.1-3.1,5.2-2.3l3.9,1.5c2.1,0.8,3.1,3.1,2.3,5.2
					C13.3,26.3,11.8,27.3,10.2,27.3z M52.9,27.3c-1.6,0-3.1-1-3.7-2.5c-0.8-2.1,0.2-4.4,2.3-5.2l3.9-1.5c2.1-0.8,4.4,0.2,5.2,2.3
					s-0.2,4.4-2.3,5.2L54.4,27C53.9,27.2,53.4,27.3,52.9,27.3z M44.5,16.7c-0.8,0-1.6-0.2-2.2-0.7c-1.8-1.2-2.3-3.7-1-5.5L43.6,7
					c1.2-1.8,3.7-2.3,5.5-1c1.8,1.2,2.3,3.7,1,5.5L47.7,15C47,16.1,45.8,16.7,44.5,16.7z M18.7,16.7c-1.3,0-2.5-0.6-3.3-1.7L13,11.5
					c-1.2-1.8-0.8-4.3,1-5.5s4.3-0.8,5.5,1l2.4,3.5c1.2,1.8,0.8,4.3-1,5.5C20.2,16.4,19.4,16.7,18.7,16.7z M31.6,12.7
					c-2.2,0-4-1.8-4-4V4.5c0-2.2,1.8-4,4-4s4,1.8,4,4v4.2C35.6,10.9,33.8,12.7,31.6,12.7z"/>
			</g>
			<g>
				<path fill="#FFFFFF" d="M76.6,44.4h-7.3v-4.1h-0.1C68,43.1,65,45,61.1,45c-5.5,0-12.5-4.1-12.5-13.7c0-7.9,5.8-13.7,14-13.7
		s13.9,5.4,13.9,14L76.6,44.4L76.6,44.4z M68.7,31.3c0-3.7-2.4-6.3-6-6.3s-6,2.6-6,6.3s2.4,6.3,6,6.3C66.3,37.7,68.7,35.1,68.7,31.3
		z"/>
				<path fill="#FFFFFF" d="M97.3,29.7v-0.2c0-3.5-1.6-4.5-3.8-4.5c-2.2,0-3.8,1.1-3.8,4.5v14.8h-7.9V29.5c0-8.1,4.8-11.9,11.7-11.9
		c7,0,11.7,3.8,11.7,12.1H97.3z"/>
				<path fill="#FFFFFF" d="M130.3,34.1c0,7.4-4.4,11-10.9,11s-10.9-3.5-10.9-11V9.9h7.9v8.4h12.4v7.1h-12.4v8.7c0,2.7,1.3,3.6,3,3.6
		s3-0.8,3-3.6v-0.8h7.9V34.1z"/>
				<path fill="#FFFFFF" d="M161,44.4h-7.3v-4.1h-0.1c-1.3,2.9-4.2,4.8-8.1,4.8C140,45,133,41,133,31.3c0-7.9,5.8-13.7,14-13.7
		s13.9,5.4,13.9,14v12.8C160.9,44.4,161,44.4,161,44.4z M153.1,31.3c0-3.7-2.4-6.3-6-6.3s-6,2.6-6,6.3s2.4,6.3,6,6.3
		C150.7,37.7,153.1,35.1,153.1,31.3z"/>
				<path fill="#FFFFFF" d="M167,44.4V6.9h7.9v22.2h0.1l8.2-10.8h9.8l-10.6,12.8l11,13.3h-10L175,33.3l0,0v11.1H167z" />
				<path fill="#FFFFFF" d="M202.3,33.5L202.3,33.5c0.7,3,2.9,4.4,5.8,4.4c2.3,0,4-0.5,5-1.9h8.2c-1.7,5.4-6.7,9-13.1,9
		c-8.2,0-14-5.8-14-13.7s5.8-13.7,14-13.7s13.9,5.8,13.9,13.7v2.2C222.1,33.5,202.3,33.5,202.3,33.5z M202.4,28.4v0.1h11.4v-0.1
		c-0.9-2.5-3-3.7-5.8-3.7C205.3,24.7,203.3,25.9,202.4,28.4z"/>
				<path fill="#FFFFFF" d="M25.6,31.6c0-3.3,2.7-6,6-6s6,2.7,6,6c0,3-2.2,5.6-5.2,6c-2.2,0.1-3.9,2-3.8,4.2s2,3.9,4.2,3.8
		c0.2,0,0.5-0.1,0.7-0.1c6.9-1,12.1-6.9,12.1-13.9c0-7.7-6.3-14-14-14s-14,6.3-14,14v23.9h8C25.6,55.5,25.6,31.6,25.6,31.6z"/>
			</g>
		</svg>

	)
}