export const LogoTheShirt = () => {
    return (
        <svg id="the-shirt" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 214.4991 90">
         <path fill="#FFFFFF" d="M56.7312,28.8645h-6.7521v-3.8236h4.5245v-7.4576H38.057v7.5363h4.6379v3.7149h-13.222
             c-2.3455-3.4744-3.8398-7.5062-5.9726-11.3345c-3.6594,0-11.5033,0.0046-11.5033,0.0046v7.6218h4.5477v3.8237H0.5145
             c0,1.3347-0.0185,2.5884,0.0046,3.8422c0.0208,1.2329-0.0948,2.4774,0.0763,3.7774h3.6664v17.0318H0.5769v7.6219h15.9955v3.738
             c-0.8559,0-1.6354-0.0093-2.4149,0.0046c-0.7564,0.0139-1.5128,0.0532-2.1605,0.0763V72.5h16.4142v-7.5941h-4.1174v-3.7404h12.9167
             c2.0125,3.8005,4.0041,7.564,5.9749,11.2882h6.7406V61.184h6.7984c2.0495-2.0448,4.0064-4.0018,5.9633-5.9564V34.8209
             C60.7283,32.864,58.7714,30.9071,56.7312,28.8645z M16.577,53.5275h-5.0728v-16.974c0.7518-0.0208,1.5891-0.0555,2.4288-0.0671
             c0.8559-0.0116,1.7141-0.0023,2.6439-0.0023V53.5275z M24.2845,53.4881V36.2528c0.8952,1.6493,1.6354,2.9678,2.3294,4.3094
             c2.2114,4.2701,4.402,8.5517,6.6526,12.9259H24.2845z M42.6672,53.4951c-0.2359,0.0347-0.384,0.0555-0.5482,0.0786
             c-2.9123-5.6372-5.8268-11.279-8.8016-17.0387h9.3498V53.4951z M55.0541,52.4634c-0.3007,0.2244-0.7402,0.5529-1.4203,1.0594
             h-3.6756V36.5211h4.018c0.2267,0.3377,0.5343,0.798,1.0779,1.6123V52.4634z"/>
         <path fill="#FFFFFF" d="M191.6953,17.928l-1.7858-0.8772l-1.7858-0.8772l0.3195-0.6508l0.3195-0.6508l0.5291,0.2596l0.5291,0.2596
             l1.4531-2.96l1.4531-2.96l-0.7874-0.3861l-0.7874-0.3861l-0.3283,0.6705l-0.3283,0.6705l-0.6905-0.3385l-0.6905-0.3385
             l0.6395-1.3028l0.6395-1.3028l3.6819,1.8078l3.6819,1.8078l-0.6398,1.3028l-0.6398,1.3028l-0.6894-0.3389l-0.6894-0.3389
             l0.3294-0.6708l0.3294-0.6708l-0.7874-0.3862l-0.7874-0.3862l-1.4524,2.9598l-1.4524,2.9598l0.5283,0.2598l0.5283,0.2598
             l-0.3199,0.6509L191.6953,17.928z M180.1658,11.6011l1.0778-3.0922l1.0778-3.0922l-0.5087-0.1771l-0.5087-0.1771l0.2376-0.6848
             l0.2375-0.6848l1.7293,0.6025l1.7293,0.6025c1.816,0.4077,2.6959,1.7865,2.6876,3.0945c-0.0083,1.308-0.9048,2.5453-2.6417,2.6698
             l0.262,1.4522l0.262,1.4522l0.5086,0.1774l0.5086,0.1774l-0.2383,0.6848l-0.2383,0.6848l-1.0312-0.3589l-1.0312-0.3589
             l-0.3039-2.0071l-0.3039-2.0071c-0.2084-0.0519-0.4438-0.1248-0.6695-0.202s-0.4416-0.159-0.6109-0.2286l-0.3502,1.0036
             l-0.3502,1.0036l0.5561,0.1935l0.5561,0.1935l-0.2383,0.6852l-0.2383,0.6852l-1.8306-0.6382l-1.8306-0.6382l0.2379-0.6852
             l0.2379-0.6852l0.509,0.1774L180.1658,11.6011z M182.8557,8.8129c1.6827,0.9476,2.9486,0.4174,3.2856-0.4425
             c0.337-0.8599-0.2549-2.0493-2.288-2.42l-0.4988,1.4312L182.8557,8.8129z M125.532,11.9713l-0.3224-0.6737l-0.3224-0.6737
             l0.7907-0.3784l0.7907-0.3784l1.4236,2.9742l1.4236,2.9742l-0.5312,0.2543l-0.5312,0.2543l0.3137,0.6546l0.3137,0.6546
             l1.7945-0.8595l1.7945-0.8595l-0.3133-0.6542l-0.3133-0.6542l-0.5313,0.2539l-0.5313,0.2539l-1.424-2.9738l-1.424-2.9738
             l0.7907-0.3788l0.7907-0.3788l0.3228,0.6739l0.3228,0.6739l0.693-0.3321l0.693-0.3321l-0.6267-1.3088l-0.6267-1.3088L126.592,8.246
             l-3.7002,1.7716l0.6267,1.3092l0.6267,1.3092l0.6934-0.3323L125.532,11.9713z M174.3534,10.1469l-0.1691,0.7056l-0.1691,0.7056
             l1.9071,0.456l1.9072,0.456l0.1683-0.7056l0.1683-0.7056l-0.5582-0.1337l-0.5582-0.1337l0.7608-3.1866l0.7608-3.1866l0.5597,0.1339
             l0.5597,0.1339l0.1684-0.7058l0.1684-0.7058l-1.9072-0.456l-1.9071-0.456l-0.1698,0.706l-0.1698,0.706l0.5597,0.1332l0.5597,0.1332
             l-0.7623,3.1866l-0.7623,3.1866l-0.5575-0.1335L174.3534,10.1469z M159.3092,10.3218c2.1101,0.042,3.1948-1.2496,3.2466-2.6265
             s-0.9294-2.8392-2.951-3.1384c-1.61-0.3763-1.9501-1.271-1.597-1.9184s1.3994-1.0476,2.5621-0.4349
             c-0.1491,0.2927-0.1242,0.6469,0.0441,0.9256s0.4801,0.4818,0.9047,0.4725c1.1556-0.3504,1.1835-1.194,0.6331-1.9131
             s-1.6791-1.3137-2.8367-1.1659c-0.9551,0.0102-1.7347,0.3133-2.2734,0.8023s-0.8366,1.164-0.8282,1.9181
             c-0.195,1.411,0.8629,1.982,2.0377,2.4614c1.1748,0.4794,2.4664,0.8671,2.7388,1.9115c0.0652,0.7131-0.5133,1.108-1.2165,1.2411
             s-1.5311,0.0043-1.9649-0.3299c0.1685-0.3201,0.1636-0.6952,0.0039-0.9901s-0.4745-0.5094-0.9257-0.5082
             c-1.2182,0.3931-1.2178,1.2829-0.6102,2.0411C156.8842,9.8282,158.0989,10.4549,159.3092,10.3218z M144.6426,3.652l0.5491,3.2294
             l0.5491,3.2294l-0.5309,0.0906l-0.5309,0.0906l0.1217,0.7155l0.1217,0.7155l3.4841-0.593l3.4841-0.593l-0.2263-1.3314
             l-0.2263-1.3314l-0.7648,0.1301l-0.7648,0.1301l0.1057,0.6231l0.1057,0.6231l-1.3875,0.2365l-1.3875,0.2365l-0.2245-1.3177
             l-0.2245-1.3177l1.4094-0.2396l1.4094-0.2396l-0.1159-0.6799l-0.1159-0.6799l-1.4094,0.2398l-1.4094,0.2398l-0.2121-1.2463
             L146.24,3.3656l1.3817-0.2352l1.3817-0.2352l0.1057,0.6233l0.1057,0.6233l0.7645-0.1297l0.7645-0.1297l-0.2263-1.3321L150.291,1.218
             l-3.4772,0.5917l-3.4772,0.5917l0.121,0.7155l0.121,0.7155l0.532-0.0902L144.6426,3.652z M167.5906,9.0971l-0.5851-0.0719
             l-0.5852-0.0719l0.1607-1.3048l0.1607-1.3048l2.1676,0.2667l2.1676,0.2667l-0.161,1.3048l-0.161,1.3048l-0.5845-0.072l-0.5844-0.072
             l-0.0882,0.7202l-0.0882,0.7202l1.9254,0.2365l1.9254,0.2365l0.0885-0.7196l0.0885-0.7196l-0.5353-0.066l-0.5353-0.066
             l0.4005-3.2513l0.4005-3.2513l0.5345,0.0656l0.5345,0.0656l0.0889-0.72l0.0889-0.72l-1.9254-0.237l-1.9254-0.237l-0.0889,0.7204
             l-0.0889,0.7204l0.5848,0.0716l0.5848,0.0716l-0.1527,1.2409l-0.1527,1.2409l-2.1673-0.2666l-2.1673-0.2666l0.1523-1.2411
             l0.1523-1.2411l0.5852,0.0722l0.5852,0.0722l0.0889-0.7202l0.0889-0.7202l-1.9253-0.237l-1.9254-0.237l-0.0889,0.7203
             l-0.0889,0.7203l0.5345,0.0658l0.5345,0.0658l-0.4005,3.2515l-0.4005,3.2515l-0.5349-0.066l-0.5349-0.066l-0.0889,0.72l-0.0889,0.72
             l1.9261,0.2368l1.9261,0.2368l0.0889-0.7198L167.5906,9.0971z M133.4651,6.7991l0.986,3.1237l0.986,3.1237l-0.5138,0.162
             l-0.5138,0.162l0.2179,0.6918l0.2179,0.6918l1.8495-0.5839l1.8495-0.5839l-0.2179-0.6914l-0.2179-0.6914l-0.5618,0.1773
             l-0.5618,0.1773l-0.3957-1.2538l-0.3957-1.2538l2.0824-0.6573l2.0824-0.6573l0.3957,1.2538l0.3957,1.2538l-0.5622,0.1775
             l-0.5622,0.1775l0.2186,0.6918l0.2186,0.6918l1.8496-0.5839l1.8496-0.5839l-0.2183-0.6918l-0.2183-0.6918l-0.5134,0.162
             l-0.5134,0.162l-0.986-3.1234l-0.986-3.1234l0.5138-0.162l0.5138-0.162l-0.2186-0.6921l-0.2186-0.6921l-1.8492,0.5839
             l-1.8492,0.5839l0.2183,0.6916l0.2183,0.6916l0.5619-0.1774l0.5618-0.1774l0.376,1.192l0.376,1.192l-2.0824,0.6573l-2.0824,0.6573
             l-0.3764-1.192l-0.3764-1.192l0.5619-0.1771l0.5619-0.1771l-0.2183-0.6921l-0.2183-0.6921l-1.8495,0.5839l-1.8495,0.5839
             l0.2182,0.6919l0.2182,0.6919l0.5138-0.1621L133.4651,6.7991z M213.9991,43.942c-1.9651,4.1004-4.6584,7.8603-7.7753,11.2625
             c-3.1169,3.4023-6.6574,6.4469-10.317,9.1168c0.358,0.2285,0.6771,0.4729,0.958,0.7436c0.2809,0.2708,0.5235,0.5679,0.7285,0.9019
             c2.3551-0.7821,4.6529,0.099,5.9033,1.6203c1.2504,1.5213,1.4534,3.6828-0.3812,5.4614c1.9465,0.4967,3.0291,2.0863,3.1991,3.7979
             c0.1699,1.7116-0.5729,3.5452-2.2775,4.5299c-0.2448,3.1269-2.391,4.595-4.7998,4.7012s-5.08-1.1494-6.375-3.4698
             c-0.2097,0.9461,0.2843,1.6761,0.8669,2.3777c0.5826,0.7016,1.2536,1.375,1.398,2.2078c-0.0033,0.7262-0.4501,1.1795-1.0415,1.4484
             c-0.5915,0.2689-1.3277,0.3535-1.9099,0.3425c-1.5916-0.1624-2.6842-1.4773-3.2701-3.082c-0.5859-1.6046-0.665-3.499-0.2297-4.8204
             c-1.3498,0.4268-2.9329,0.6617-4.4205,0.4862c-1.4876-0.1754-2.8798-0.761-3.8478-1.9751c-1.5688,0.7959-3.571,1.6477-5.4966,1.8708
             c-1.9256,0.2232-3.7744-0.1823-5.0364-1.9007c-1.7919,1.7843-4.4819,2.2644-6.4236,1.5807
             c-1.9417-0.6836-3.1352-2.5309-1.9342-5.4013c-0.0583,0.0683-0.1713,0.1991-0.2851,0.3304s-0.2283,0.2632-0.2895,0.3336
             l-0.0075-0.0056l-0.0075-0.0056c-2.1242,2.1836-3.9574,3.8916-5.7505,4.6856c-1.7931,0.794-3.5462,0.6742-5.5101-0.7978
             c-1.0444,0.7346-2.4228,1.2199-3.7399,1.2215s-2.5728-0.4804-3.3717-1.6805c-0.1022,0.0592-0.2043,0.1201-0.3073,0.1801
             c-0.1029,0.0599-0.2066,0.1189-0.3121,0.1743c-1.4411,0.8356-3.2812,1.3805-5.0312,1.3363c-1.75-0.0442-3.4099-0.6775-4.4906-2.1982
             c-0.8752,0.7193-1.7883,1.3744-2.7457,1.9427c-0.9574,0.5684-1.9591,1.0501-3.0115,1.4228
             c-0.5981,0.9382-1.2316,2.2215-1.9827,3.3939c-0.7512,1.1724-1.62,2.234-2.6887,2.7289c-3.0929,1.5338-5.4395,0.3235-6.2574-1.6399
             c-0.818-1.9634-0.1073-4.6798,2.9144-6.1581c-2.4231-1.5094-1.2791-4.8704,0.6188-8.2798c1.8979-3.4094,4.5497-6.8672,5.1421-8.5701
             c-3.6121-2.56-6.9388-5.5137-9.9094-8.7921c-2.9706-3.2784-5.585-6.8815-7.7725-10.7405c0.8879-3.607,3.101-6.9177,5.7713-9.9576
             s5.7977-5.809,8.5143-8.333c13.9979-12.0922,33.0602-16.0007,50.7158-12.7309S205.6274,27.3211,213.9991,43.942z M144.0663,17.3837
             h6.7795h6.7795v4.4419v4.4419l-4.8001,5.8746l-4.8001,5.8746c5.9555,1.1235,9.6154,5.5382,10.7946,10.6884
             s-0.1223,11.0358-4.0898,15.1014c1.3353,0.2619,2.1967,0.9869,2.7012,1.6408c0.5045,0.6538,0.6521,1.2365,0.5599,1.2135
             l2.4458,0.0317l2.4458,0.0317c-0.5773-0.9181-0.7201-1.9722-0.5086-2.9912s0.7772-2.0029,1.6167-2.7807v-1.1884v-1.1884h5.4437
             h5.4437c0.011-1.2952-0.005-7.3228-0.0064-13.2635s0.0117-11.7947,0.081-12.7426h-0.0728h-0.0728
             c0.0542,0.3602-0.9483,1.537-2.0989,2.7251c-1.1505,1.1881-2.449,2.3876-2.9866,2.7933l-3.6173-3.8691l-3.6174-3.8691l6.9101-6.481
             l6.9101-6.481c-5.261-1.4661-10.7278-2.2397-16.1761-2.2604C154.6827,15.1062,149.2531,15.8383,144.0663,17.3837z M124.3248,59.8404
             l1.9645-3.0316l1.9644-3.0316c7.6809,6.98,14.7074,4.9772,17.2319,1.3708c2.5244-3.6064,0.5468-8.8165-9.7808-8.251l-1.3264-2.8656
             l-1.3264-2.8656c0.7058-0.8355,3.1736-4.0833,5.6549-7.2263c2.4813-3.143,4.9762-6.1812,5.7363-6.5976v-0.0728v-0.0728
             c-0.5673,0.1423-2.6226,0.1964-4.7438,0.2149c-2.1213,0.0185-4.3086,0.0014-5.14,0.0016v2.6864v2.6864h-5.3728h-5.3729v-2.1789
             v-2.1789c-2.34,1.9869-4.8612,4.4082-7.1967,7.0516c-2.3355,2.6434-4.4852,5.5089-6.0824,8.3842
             c1.7923,3.0902,3.8778,5.9563,6.1963,8.6138C119.0493,55.1351,121.6007,57.5842,124.3248,59.8404z M180.7546,65.8997l-1.1324-0.6081
             l-1.1324-0.6081c-1.6239,2.1491-3.3893,4.7664-5.3073,7.2019c-1.9179,2.4355-3.9882,4.6891-6.2218,6.1109
             c-2.1925,0.8702-1.8577-0.4931-0.9337-2.2235s2.4371-3.8277,2.6012-4.4256c1.3848-1.8786,0.2383-3.2786-0.8775-4.5387
             c-1.1158-1.2601-2.2008-2.3804-0.6931-3.6997l-0.6996-0.8992l-0.6996-0.8992c-0.9053,0.6417-1.6038,1.5718-1.8591,2.5582
             c-0.2554,0.9865-0.0676,2.0294,0.7995,2.8967c-0.7553,1.0729-1.5629,2.21-2.4155,3.355c-0.8525,1.145-1.75,2.2978-2.685,3.4021
             c0,0,0,0,0,0.0005c0,0.0005,0,0.0014,0,0.0033l-0.0019-0.0019l-0.0019-0.0019c-0.3366,0.6347-2.2635,2.5993-3.9147,3.811
             c-1.6512,1.2117-3.0268,1.6703-2.2608-0.7069c0.5143-0.9031,1.4669-2.5677,2.4238-4.2399c0.9569-1.6722,1.9181-3.3519,2.4496-4.2855
             h-0.8563h-0.8563c-0.2084-2.3425-1.8326-3.1573-3.6326-2.9244c-1.8,0.2328-3.7759,1.5133-4.6876,3.3612
             c-0.2759,0.487-0.8676,1.5036-1.456,2.5142c-0.5883,1.0106-1.1734,2.0151-1.4359,2.4777c0,0-0.0009-0.0019-0.0023-0.0037
             c-0.0014-0.0019-0.0033-0.0037-0.0051-0.0037c-0.913,1.7383-3.977,3.8117-6.3502,4.4936s-4.0556-0.0278-2.2053-3.8556
             c1.9612,0.811,4.0627,0.2272,5.7416-1.0495c1.6789-1.2768,2.9351-3.2465,3.2057-5.2077c-0.0799-2.24-1.7611-2.9915-3.6194-2.8072
             c-1.8584,0.1843-3.8938,1.3043-4.6821,2.8074c-0.407,0.5982-1.1057,1.796-1.8002,3.0023c-0.6945,1.2063-1.3847,2.4211-1.7746,3.0532
             c-0.8276,0.8766-1.9923,2.0592-3.2339,3.1235c-1.2415,1.0643-2.5599,2.0103-3.6948,2.4137
             c0.8322-1.4238,2.5035-4.2858,4.1642-7.1294c1.6607-2.8436,3.3107-5.6689,4.1003-7.0193h-1.5092h-1.5093
             c-1.202,1.8079-2.5212,4.715-4.1344,7.2604c-1.6132,2.5454-3.5204,4.729-5.8986,5.0898c-0.8068-0.0881,0.7133-2.9346,2.458-5.8796
             c1.7448-2.945,3.7143-5.9885,3.8065-6.4706h-1.5074h-1.5074c-0.3124,0.6425-1.9601,3.3576-3.5388,6.0632
             c-1.5787,2.7057-3.0885,5.4019-3.1251,6.007c-0.2942,2.2468,0.8189,2.8592,2.2456,2.6388c1.4267-0.2205,3.1671-1.2739,4.1273-2.3588
             l-0.8264,1.4197l-0.8264,1.4197c-4.0067,0.6692-6.2421,3.1387-6.5647,5.0508c-0.3226,1.9122,1.2676,3.2671,4.9121,1.7072
             c0.7185-0.4177,1.4787-1.5207,2.1721-2.6894c0.6934-1.1687,1.3201-2.403,1.7715-3.0836c1.2011-0.3467,2.5036-0.9837,3.7719-1.7967
             s2.5026-1.8019,3.5672-2.8523c0.2953,2.4908,2.2039,3.3241,4.3804,3.1974c2.1765-0.1267,4.6211-1.2135,5.9884-2.563
             c-0.0082,1.9108,1.1212,2.6294,2.5376,2.5926c1.4165-0.0368,3.1201-0.829,4.2605-1.9396c1.0115,2.5131,2.9798,2.4027,5.0188,1.2701
             c2.039-1.1326,4.1488-3.2875,5.4433-4.8633c0,0,0,0,0.0005,0c0.0005,0,0.0014,0,0.0033,0c0.8462-0.9793,1.6773-2.0314,2.5049-3.1209
             c0.8276-1.0894,1.6516-2.2162,2.4836-3.3451c1.0307,0.7225,0.769,1.8607,0.179,3c-0.59,1.1394-1.5083,2.2799-1.7908,3.0072
             c-0.7881,0.9798-1.4743,2.4849-1.4878,3.7458c-0.0136,1.2609,0.6455,2.2775,2.5479,2.2801
             c1.5106-0.0684,2.9521-0.8759,4.2769-1.9584c1.3248-1.0825,2.533-2.4401,3.5768-3.6087h0.0019h0.0019
             c0.7242-0.8289,1.5219-1.8363,2.276-2.8216c0.7542-0.9853,1.4648-1.9486,2.0148-2.6893c-0.4435,1.2732-0.725,4.2757-1.3602,6.4157
             s-1.6242,3.4175-3.4828,1.2406l-0.9403,0.4925l-0.9402,0.4925c0.5117,1.9591,2.1454,2.5009,3.9835,2.3664s3.8806-0.9452,5.21-1.6911
             c-0.1106-0.3295-0.1771-0.6778-0.2047-1.0307c-0.0275-0.353-0.0161-0.7106,0.0293-1.0587
             c-0.2519,0.1232-0.4972,0.2435-0.7276,0.3661c-0.2304,0.1227-0.4459,0.2476-0.638,0.3801c0.5053-1.6785,0.7318-3.6247,1.0451-5.5695
             S179.9016,67.5723,180.7546,65.8997z M194.7054,65.2245c-1.8523-0.8667-3.2483-0.498-4.1827,0.4889
             c-0.9344,0.9868-1.4073,2.5917-1.4135,4.1974c-0.7751-0.0177-1.5535-0.4008-2.3558-0.6779
             c-0.8023-0.2771-1.6284-0.4481-2.499-0.0419c-0.883,0.2806-1.6205,1.0986-1.9733,2.0311c-0.3528,0.9325-0.3207,1.9796,0.3353,2.7182
             c-1.6406,0.9155-1.9843,2.5304-1.4787,3.8976s1.8604,2.4867,3.617,2.4113c1.0025,0.05,1.9273-0.0961,2.8465-0.3183
             c0.9192-0.2222,1.8329-0.5204,2.8133-0.7746c0.2407,0,0.334,0.1306,0.3671,0.2612s0.0061,0.2612,0.0061,0.2612
             c-0.8036,1.2383-1.1369,2.9172-0.9291,4.4368c0.2077,1.5196,0.9565,2.8799,2.3169,3.4813c1.7245-0.0113,1.6497-0.338,1.0931-1.0045
             c-0.5566-0.6666-1.5951-1.6731-1.7981-3.0443c-0.1101-0.7741,0.0681-1.6295,0.3414-2.2913s0.6418-1.1301,0.9122-1.1301
             c0.602,0.6466,1.0675,1.3605,1.5794,2.037c0.5119,0.6765,1.07,1.3158,1.8572,1.8133c1.3239,0.9775,3.1373,1.0226,4.5081,0.3549
             c1.3708-0.6677,2.2992-2.0481,1.8533-3.9218l0.0075,0.013l0.0075,0.013c1.9851-0.6403,2.7021-2.2207,2.3894-3.6544
             c-0.3127-1.4337-1.6551-2.7207-3.7887-2.7741c-0.5484-0.071-0.4635-0.3705-0.1626-0.7413c0.3008-0.3708,0.8176-0.8129,1.1329-1.1692
             c1.5821-1.7657,1.0203-3.3406-0.3184-4.2337c-1.3387-0.8931-3.4543-1.1044-4.9798-0.143c-0.1066-0.5693-0.3747-1.0653-0.7451-1.4829
             S195.2214,65.4806,194.7054,65.2245z M211.0142,43.942c-1.5757-2.6779-3.341-5.1961-5.289-7.5516s-4.0789-4.5481-6.3858-6.5747
             c0.0877,0.0821,0.1996,0.1875,0.3111,0.2933c0.1115,0.1059,0.2225,0.2122,0.3083,0.2962c-1.5431-1.4875-3.3605-2.9774-5.4869-4.461
             c-2.1264-1.4836-4.5618-2.9608-7.3408-4.4229v18.527v18.527h5.6284h5.6284c2.6317-1.8837,4.9527-4.1726,7.0336-6.6754
             C207.5024,49.3974,209.3431,46.6809,211.0142,43.942z M172.1322,85.8403c0.0477,1.4068-0.8982,2.1079-1.8442,2.1066
             c-0.946-0.0012-1.892-0.7047-1.8447-2.1068c-0.0511-1.4003,0.8939-2.0996,1.8403-2.0992S172.1777,84.4415,172.1322,85.8403z
              M171.676,85.8403c0.0451-1.1407-0.6702-1.7134-1.3862-1.7146c-0.7159-0.0012-1.4324,0.5692-1.3896,1.7147
             c-0.0432,1.1502,0.6737,1.725,1.39,1.7249C171.0065,87.5651,171.722,86.99,171.676,85.8403z M158.2008,83.7371
             c-0.03-0.0315-0.3671,0.0531-0.7088,0.1481s-0.688,0.2004-0.7363,0.2104l0.0488,0.1909l0.0488,0.1909l0.454-0.1203l0.454-0.1203
             v1.6535v1.6535h-0.4992h-0.4992v0.1909v0.1909h1.209h1.209V87.735v-0.1909h-0.4901h-0.4901v-1.9035V83.7371z M162.7227,84.17
             c0.8018,0.9934,0.6795,2.5595,0.1,3.6448c-0.5795,1.0853-1.6162,1.6896-2.6433,0.7594c-0.4139-0.4271-0.1537-0.7311,0.1277-0.8085
             c0.2813-0.0774,0.5837,0.0717,0.2542,0.5508c0.5667,0.3941,1.1284,0.31,1.5513-0.0943c0.4229-0.4043,0.7069-1.1289,0.718-2.0161
             c-1.7633,1.5668-2.8069,0.4568-2.9013-0.7444S160.6894,82.9681,162.7227,84.17z M162.7795,85.2923
             c0.0132-0.8011-0.6437-1.1927-1.2881-1.1747c-0.6444,0.018-1.2762,0.4455-1.2129,1.2827c-0.0324,0.8006,0.6205,1.1423,1.2677,1.0995
             C162.1933,86.457,162.8348,86.0297,162.7795,85.2923z M167.0193,84.17c0.803,0.9928,0.6812,2.559,0.1018,3.6444
             c-0.5794,1.0854-1.6164,1.6901-2.6436,0.7598c-0.4109-0.4275-0.1513-0.7314,0.1292-0.8086c0.2805-0.0773,0.5818,0.072,0.2541,0.5509
             c0.5657,0.3942,1.1276,0.3099,1.5506-0.0946c0.423-0.4045,0.7072-1.1292,0.7173-2.0158c-1.7642,1.5668-2.8078,0.457-2.9023-0.7442
             C164.132,84.2608,164.9868,82.9684,167.0193,84.17z M167.0791,85.2923c0.0121-0.8005-0.645-1.1922-1.2892-1.1745
             c-0.6442,0.0177-1.2757,0.445-1.2125,1.2824c-0.0326,0.8009,0.6202,1.1425,1.2674,1.0995
             C166.4921,86.4568,167.1338,86.0293,167.0791,85.2923z M146.2204,86.0079c0,0.0619-0.002,0.1115-0.0062,0.1548
             c-0.0042,0.0434-0.0106,0.0805-0.0193,0.1177h-1.3832h-1.3832c0.0219,0.7168,0.4415,1.122,0.9762,1.2619
             c0.5346,0.1398,1.1843,0.0143,1.6663-0.3305l-0.0208,0.2157l-0.0208,0.2157c-0.6141,0.4117-1.3969,0.4454-2.0159,0.1776
             c-0.6191-0.2678-1.0744-0.8369-1.0338-1.6308c-0.0882-1.1013,0.7457-1.7124,1.5898-1.7654
             C145.4136,84.3716,146.2678,84.8767,146.2204,86.0079z M145.7882,85.9073c0.0032-0.7495-0.5565-1.1342-1.131-1.1391
             c-0.5745-0.0049-1.1639,0.3698-1.2199,1.1391h1.1755H145.7882z M148.467,85.9219c-0.9532-0.1217-1.0825-0.5424-0.8394-0.8519
             c0.2431-0.3094,0.8585-0.5076,1.3947-0.1843c-0.1568,0.2397-0.0168,0.4423,0.1643,0.5095s0.403-0.001,0.4101-0.3026
             c-0.0667-0.56-0.7247-0.7708-1.3516-0.7062s-1.2229,0.4048-1.1655,0.9467c-0.085,0.572,0.4436,0.7586,1.0115,0.9179
             c0.5679,0.1592,1.1752,0.2911,1.2477,0.7537c0.0383,0.3557-0.3402,0.5434-0.7644,0.5959c-0.4242,0.0525-0.8942-0.0302-1.0387-0.2154
             c0.1906-0.4714-0.0868-0.5836-0.3188-0.4947s-0.4187,0.3789-0.0469,0.7119c0.8075,0.7022,1.8427,0.4245,2.3206-0.1
             C149.9684,86.9779,149.889,86.2067,148.467,85.9219z M151.4097,87.4027c-0.0487-0.0476-0.0572-0.6504-0.0557-1.2628
             c0.0015-0.6124,0.0131-1.2342,0.0046-1.3198h0.5407h0.5407v-0.1924v-0.1924h-0.5407h-0.5407v-0.5276v-0.5276l-0.2113,0.0758
             l-0.2113,0.0758v0.4518v0.4518h-0.3159h-0.3159v0.1924v0.1924h0.3159h0.3159c0.0375,0.4979-0.0905,1.5162,0.0209,2.2734
             c0.1115,0.7572,0.4624,1.2534,1.4577,0.7071l0.0332-0.2194l0.0332-0.2193c-0.1449,0.0982-0.3655,0.1896-0.5735,0.2159
             S151.5041,87.5651,151.4097,87.4027z M153.5463,87.0952c-0.2822-0.0061-0.4235,0.2212-0.4237,0.4484
             c-0.0002,0.2272,0.1409,0.4544,0.4237,0.4479c0.2824,0.0065,0.4233-0.2207,0.4231-0.448
             C153.9693,87.3163,153.8281,87.089,153.5463,87.0952z M197.4502,88.474c0.0044,0.0175,0.0109,0.0331,0.0201,0.0465
             c0.0091,0.0134,0.0208,0.0245,0.0353,0.0329v0.0179v0.0179c0,0-0.0809,0-0.1618,0c-0.0809,0-0.1618,0-0.1618,0
             c-0.0221-0.2299-0.0235-0.3838-0.0858-0.4723c-0.0624-0.0885-0.1857-0.1117-0.452-0.0801v0.2762v0.2762h-0.1443h-0.1443v-0.7055
             v-0.7055c0.2418-0.0571,0.6524-0.0238,0.911,0.0993c0.2586,0.1231,0.3651,0.3361-0.0015,0.6382
             c0.1197,0.037,0.1584,0.1323,0.1709,0.2404S197.435,88.3854,197.4502,88.474z M197.1879,87.6185
             c0.0105-0.1448-0.0829-0.1884-0.2002-0.1986c-0.1173-0.0102-0.2584,0.013-0.3435,0.0018v0.1898v0.1898
             c0.0822-0.0118,0.2214,0.0111,0.3385,0.0027C197.0997,87.7955,197.1945,87.7557,197.1879,87.6185z M198.0246,87.1278
             c0.6159,0.9782,0.0191,1.7462-0.7411,1.9798c-0.7603,0.2336-1.6841-0.0673-1.7222-1.227c-0.0215-0.6467,0.4232-1.108,0.9589-1.271
             C197.0559,86.4465,197.6825,86.5817,198.0246,87.1278z M197.9749,87.8808c0.0099-0.6924-0.5285-1.0386-1.0669-1.0386
             c-0.5384,0-1.0767,0.3462-1.0668,1.0387c-0.0101,0.6922,0.5284,1.0383,1.0669,1.0383
             C197.4465,88.9192,197.985,88.573,197.9749,87.8808z M119.4189,84.672c0.1387,1.4945,0.7985,1.1129,1.47,0.2633
             c0.6715-0.8496,1.3546-2.1672,1.5397-2.5449c-1.0154,0.3941-1.7253,0.7876-2.2025,1.171
             C119.7488,83.9449,119.5041,84.3182,119.4189,84.672z M138.6705,72.7026c0.9564,0.1205,1.7824-0.2254,2.4668-0.7974
             c0.6845-0.572,1.2275-1.3701,1.6181-2.1541c0.9265-1.5464,0.5499-2.3777-0.2188-2.531c-0.7687-0.1533-1.9296,0.3715-2.5717,1.5371
             c-0.249,0.3849-0.6698,1.1183-1.0889,1.8575c-0.4191,0.7392-0.8365,1.4843-1.079,1.8926c0.1315,0.0471,0.2758,0.0891,0.4242,0.1227
             C138.3698,72.6635,138.5224,72.6888,138.6705,72.7026z M154.4703,69.4574c0.597-1.4443-0.0794-2.0633-1.0054-2.0545
             c-0.926,0.0087-2.1016,0.6451-2.5028,1.7117c-0.5976,1.0461-1.3534,2.3632-2.1076,3.6772c-0.7542,1.3139-1.5066,2.6247-2.0974,3.658
             c-0.6701,1.5927-0.0537,1.9091,0.9047,1.5805c0.9585-0.3286,2.259-1.3022,2.9573-2.2897c0.6316-0.7205,1.3551-1.8654,2.0404-3.0432
             S153.9918,70.3091,154.4703,69.4574z M194.2094,70.6446h1.2167h1.2166v0.5407v0.5407c0,0-0.1694,0-0.3389,0s-0.3389,0-0.3389,0
             v0.1348v0.1348h0.3389h0.3389l0.4664,0.473l0.4664,0.473v1.3525v1.3525l-0.4664,0.473l-0.4664,0.473c0,0-0.1694,0-0.3389,0
             s-0.3389,0-0.3389,0v0.6763v0.6763h-0.5608h-0.5607l-0.4056-0.6763l-0.4056-0.6763h-1.1295h-1.1295v0.1356v0.1356h0.3389h0.3389
             v0.5407v0.5407c0,0-0.6114,0-1.2228,0s-1.2228,0-1.2228,0v-0.5407v-0.5407h0.3367h0.3367c0,0,0-0.0678,0-0.1356s0-0.1356,0-0.1356
             h-1.0436h-1.0436v-0.5364v-0.5363h0.2704h0.2704c0,0,0-0.6081,0-1.2163c0-0.6081,0-1.2163,0-1.2163h-0.2704h-0.2704
             c0,0,0-0.2729,0-0.5458s0-0.5458,0-0.5458h1.0501h1.0501v-0.1348V71.726c0,0-0.1687,0-0.3374,0s-0.3374,0-0.3374,0v-0.5407v-0.5407
             h0.8781h0.8781l0.4212,0.6755l0.4212,0.6755h1.1346h1.1346c0,0,0-0.0674,0-0.1348c0-0.0674,0-0.1348,0-0.1348h-0.3378h-0.3378
             v-0.5407V70.6446z M190.6918,73.0786c0,0-0.1197,0-0.2394,0c-0.1197,0-0.2394,0-0.2394,0v1.2155v1.2155c0,0,0.1197,0,0.2394,0
             s0.2394,0,0.2394,0v-1.2155V73.0786z M193.3823,75.5096l-0.7284-1.2155l-0.7284-1.2155c0,0-0.0381,0-0.0761,0
             c-0.0381,0-0.0762,0-0.0762,0v1.2155v1.2155h0.8045H193.3823z M194.885,73.0786h-0.8038h-0.8038l0.7291,1.2155l0.7291,1.2155h0.0747
             h0.0747v-1.2155V73.0786z M195.965,75.5096c0,0,0.0678,0,0.1356,0c0.0678,0,0.1355,0,0.1356,0l0.1282-0.1348l0.1282-0.1348
             c0,0,0-0.4733,0-0.9466c0-0.4733,0-0.9466,0-0.9466l-0.1282-0.1341l-0.1282-0.1341c0,0-0.0678,0-0.1356,0s-0.1356,0-0.1356,0v1.2155
             V75.5096z"/>
         <rect x="83.6876" y="0.5" fill="#FFFFFF" width="1.8545" height="89"/>
         </svg>         
    )
}