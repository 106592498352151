export const LogoVinal = () => {
    return (
        <svg id="vinal" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 136.6 56">
<linearGradient id="Record_00000068661421580894280920000000495207511277695114_" gradientUnits="userSpaceOnUse" x1="0.5" y1="35.4138" x2="40.6724" y2="35.4138">
	<stop  offset="0" style={{ stopColor: "#FFFFFF" }}/>
	<stop  offset="0.1078" style={{ stopColor: "#FFFFFF",stopOpacity:0.9838 }}/>
	<stop  offset="1" style={{ stopColor: "#FFFFFF",stopOpacity:0.85 }}/>
</linearGradient>
<path id="Record_00000075861699803507334200000015076546981884616345_" fill="url(#Record_00000068661421580894280920000000495207511277695114_)" d="
	M24.2,35.4c0,2-1.6,3.6-3.6,3.6S17,37.4,17,35.4c0-2,1.6-3.6,3.6-3.6S24.2,33.4,24.2,35.4z M20.6,24.8c5.8,0,10.6,4.8,10.6,10.6
	c0,1.9-0.5,3.7-1.4,5.3H40c0.5-1.7,0.7-3.4,0.7-5.3c0-11.1-9-20.1-20.1-20.1c0,0-0.1,0-0.1,0c-0.1,0-0.3,0.1-0.3,0.2
	c-0.1,0.1,0,0.3,0,0.4c0.2,0.3,0.4,0.7,0.4,1.1c0,0.4-0.2,0.8-0.4,1.2c-0.2,0.3-0.2,0.6,0,0.9c0.3,0.3,0.4,0.7,0.4,1.2
	c0,0.4-0.2,0.8-0.4,1.2c-0.2,0.3-0.2,0.6,0,0.9c0.2,0.2,0.3,0.5,0.4,0.9c0.1,0.4,0,0.8-0.2,1.2c-0.1,0.1-0.1,0.2,0,0.3
	C20.4,24.7,20.5,24.8,20.6,24.8z M33.6,50c-0.4-0.1-0.7-0.3-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.3-0.2-0.6-0.6-0.7
	c-0.4-0.1-0.8-0.3-1.1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.3-0.2-0.6-0.6-0.7c-0.4-0.1-0.8-0.3-1.1-0.6c-0.2-0.3-0.4-0.6-0.4-0.9
	c0-0.1-0.1-0.2-0.2-0.3c-0.1,0-0.2,0-0.3,0.1c-1.9,1.6-4.3,2.5-6.9,2.5C14.7,46,10,41.3,10,35.4c0-3.7,1.9-7,4.9-8.9
	c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.3-0.1-0.5-0.4-0.7-0.6c-0.2-0.4-0.3-0.8-0.3-1.2c0-0.3-0.2-0.6-0.5-0.7
	c-0.4-0.1-0.7-0.4-1-0.8c-0.2-0.4-0.3-0.8-0.3-1.2c0-0.3-0.2-0.6-0.5-0.7c-0.4-0.1-0.7-0.4-1-0.7c-0.2-0.4-0.3-0.8-0.3-1.1
	c0-0.1-0.1-0.3-0.2-0.3s-0.3-0.1-0.4,0c-5.6,3.6-9.4,9.8-9.4,17c0,11.1,9,20.1,20.1,20.1c5,0,9.6-1.9,13.2-4.9
	c0.1-0.1,0.1-0.2,0.1-0.3S33.7,50,33.6,50z"/>
<path id="Logotype_00000158720328968439909410000008443725589302524034_" fill="#FFFFFF" d="M54.4,40.7V15.8H64v24.8H54.4z
	 M59.2,1.4c-3,0-5.4,2.4-5.4,5.4s2.4,5.4,5.4,5.4c3,0,5.4-2.4,5.4-5.4S62.2,1.4,59.2,1.4z M83.9,40.7h9.6V25.9
	c0-5.9-4.7-10.6-10.6-10.6c-2.7,0-5.2,0.7-7.1,2v-1.4l-9.6,0v24.8h9.6V27.1c0-2.2,1.8-4.1,4.1-4.1c2.2,0,4.1,1.8,4.1,4.1V40.7z
	 M126.6,0.5v40.2h9.6V0.5H126.6z M40.6,40.7l13.3-24.8l-10.8,0L29.8,40.7H40.6z M107.8,15.3c-7.1,0-12.9,5.8-12.9,12.9
	s5.8,12.9,12.9,12.9c3,0,5.6-1.1,6.9-2.7v2.1h9.6V15.8l-9.6,0v1.5C112.7,16.1,110.3,15.3,107.8,15.3z M115.2,28.3
	c0,3-2.4,5.4-5.4,5.4c-3,0-5.4-2.4-5.4-5.4s2.4-5.4,5.4-5.4C112.8,22.8,115.2,25.3,115.2,28.3z"/>
</svg>
    )
}